// Função utilitária para remover o cookie
export const removeSessionDataCookie = () => {
  document.cookie =
    "sessionData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export function getSessionDataFromCookie() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Verifica se o cookie começa com 'sessionData'
    if (cookie.startsWith("sessionData=")) {
      // Remove 'sessionData=' do cookie e decodifica o valor JSON
      const sessionDataJSON = decodeURIComponent(
        cookie.substring("sessionData=".length)
      );
      // Retorna o objeto JavaScript
      return JSON.parse(sessionDataJSON);
    }
  }
  // Retorna null se o cookie não for encontrado
  return null;
}

export function updateSessionCookie(sessionData) {
  // Define o novo valor do cookie
  const cookieValue = JSON.stringify(sessionData);
  // Define a data de expiração do cookie (30 dias a partir de agora)
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  // Formata a data de expiração conforme necessário para o cookie
  const expires = expirationDate.toUTCString();
  // Define o cookie com o novo valor e a nova data de expiração
  document.cookie = `sessionData=${cookieValue}; expires=${expires}; path=/`;
}

export function listenCookieChange(callback, time = 1000) {
  let lastCookie = document.cookie;
 const interval = setInterval(()=> {
    let cookie = document.cookie;
    if (cookie !== lastCookie) {
      try {
        callback({oldValue: lastCookie, newValue: cookie});
      } finally {
        lastCookie = cookie;
      }
    }
  }, time);

  return interval;
}