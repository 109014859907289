// @ts-nocheck
import { SSRKeycloakProvider, SSRCookies } from '@react-keycloak/ssr';

const keycloakCfg = {
  url: process.env.KEYCLOAK_URL,
  realm: process.env.KEYCLOAK_REALM || "",
  clientId: process.env.KEYCLOAK_CLIENT_ID || "",
};

const AuthProvider = ({ children, cookies }) => {
  const origin = process.env.WEBAPP_BASE_URL;

  return (
    <SSRKeycloakProvider
      keycloakConfig={keycloakCfg}
      onTokens={({ token }) => {
        localStorage.setItem("keycloakToken", token);
      }}
      initConfig={{
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: origin
          ? origin + "/silent-check-sso.html"
          : undefined,
      }}
      persistor={SSRCookies(cookies)}
    >
      {children}
    </SSRKeycloakProvider>
  );
};

export { AuthProvider };
