import axios from "axios";

export async function performLogin({
  produto = "OPOPULAR",
  email,
  tipoDispositivo,
}) {

  return await axios
    .post(`/sessao/post-sessao`, {
      produto,
      email,
      tipoDispositivo,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return { status: false, data: {
        payload:{
          product:produto,
          login:email,
          device:tipoDispositivo
        },
        erro: e
      } };
    });
}
