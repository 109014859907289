import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCopyProtection } from 'hooks/useCopyProtection';
import { ToastContainer } from 'react-toastify';
import cookie from 'cookie';
import App, { AppContext } from 'next/app';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';
import '../styles/player.css';
import '../styles/classi.css';
import { AuthProvider } from 'providers/AuthProvider/AuthProvider';
import { NextIncomingMessage } from 'next/dist/server/request-meta';
import SessionProvider from 'providers/Session';

function MyApp({ Component, pageProps, cookies }) {
  useCopyProtection();

  const router = useRouter();

  useEffect(() => {
    // Handle navigation control with tag a
    const handleNavigation = (event) => {
      const anchor = event.target.closest('a');
      if (anchor && !!anchor?.href && anchor?.href?.includes("daqui.opopular.com.br/lojadodaqui")) {
        event.preventDefault();
        window.location.href = anchor.href
      }
    };
    document.addEventListener('click', handleNavigation);


    // Save the last visited URL in the session storage
    const handleRouteChangeStart = (url) => {
      const currentURL = window.location.href;
      sessionStorage.setItem('lastVisitedURL', currentURL);
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router]);

  return (
    <AuthProvider cookies={cookies}>
      <SessionProvider>
        <Component {...pageProps} />
        <ToastContainer />
      </SessionProvider>
    </AuthProvider>
  );
}

function parseCookies(req?: NextIncomingMessage) {
  if (!req || !req?.headers) {
    return {};
  }
  return cookie.parse(req?.headers?.cookie || '');
}

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context);
  return {
    ...appProps,
    cookies: parseCookies(context?.ctx?.req),
  };
};

export default MyApp;
