const enabled = true;
const runOnlyOnServer = true;

/**
 * Internal function to log messages after validate parameters
 *
 * @param type {string} console method
 * @param args {array} all console.log args
 * @param disabled {boolean} if true, logging is skipped
 * @private
 */
const _logMessage = (type, args, disabled = false) => {
  const isServerSide = typeof window === 'undefined';

  if (
    (enabled && !disabled) &&
    (!runOnlyOnServer || isServerSide)
  ) {
    console[type](...args);
  }
}

const debugTools = {
  /**
   * Log messages to console
   *
   * @param args {...any} args arguments for console.log, with optional last argument to disable logging
   */
  log: (...args) => {
    const lastArg = args[args.length - 1];
    const disabled = typeof lastArg === 'boolean' ? args.pop() : false;

    _logMessage('log', args, disabled);
  },

  /**
   * Log error messages to console
   *
   * @param args {...any} args arguments for console.error, with optional last argument to disable logging
   */
  error:(...args) => {
    const lastArg = args[args.length - 1];
    const disabled = typeof lastArg === 'boolean' ? args.pop() : false;

    _logMessage('error', args, disabled);
  }
}

export { debugTools }
