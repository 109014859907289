let isCopyProtectionLoaded = false

export function useCopyProtection() {
  if (typeof window !== 'undefined') {
    if (typeof document !== 'undefined' && !isCopyProtectionLoaded) {
      isCopyProtectionLoaded = true
      window.document.addEventListener('copy', (event) => {
        event.preventDefault()
        const title = document.title
        const link = window.location.href
        const text = `${title} - Veja mais em: ${link}`
        event?.clipboardData?.setData('text/plain', text)
        event?.clipboardData?.setData('text/html', text)
      })
    }
  }
}