import { Block } from "@xprog/prensa";
import { PrensaEngineCSSProp } from "@xprog/prensa/types";

const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
    const containerStyles: PrensaEngineCSSProp = {display:'flex',justifyContent:'center',alignItems:'center', minHeight:'100vh'}
  
    return <Block css={containerStyles}>
        <div>
           {children}
        </div>
    </Block>;
  };

  export {
    Container
  };
  