import axios from "axios";

export async function updateSession(idSessao) {


  return await axios
    .get(`/paywall/sessao/${idSessao}`)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return { status: false, data: e };
    });
}
