import { Button } from '@xprog/prensa'
import { ButtonProps } from '@xprog/prensa/types'
import React from 'react'

export interface CallToActionOutlinedButtonProps extends ButtonProps {}

const CallToActionOutlinedButton: React.FC<CallToActionOutlinedButtonProps> = ({ css, children, ...otherProps }) => {

  const styles = {
    button: {
      backgroundColor: 'white',
      borderColor: '#e6e6e6',
      borderRadius: '$alternative',
      height: '56px',
      '&:disabled': {
        backgroundColor: '#A7A7A7',
        borderColor: '#A7A7A7'
      },
      ...css?.button
    },
    label: {
      color: '$primary800',
      fontFamily: '$primary',
      fontSize: '$caption3',
      fontWeight: '$caption3',
      lineHeight: '$caption3',
      ...css?.label
    }
  }

  return (
    <Button
      variant='filled'
      css={styles}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

export default CallToActionOutlinedButton